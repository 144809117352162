module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"description":"HD screensavers and wallpapers, direct to your desktop.","facebook":{"appId":"371322199980313"},"openGraph":{"title":"League Displays","description":"HD screensavers and wallpapers, direct to your desktop.","type":"website","url":"http://na.leagueoflegends.com/en/featured/league-displays","image":"https://lolstatic-a.akamaihd.net/frontpage/apps/prod/rg-league-display-2017/en_US/cb24025fade09e3f965776440dffcc65024d3266/assets/img/share/displays_1024.jpg"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["ar-ae","zh-tw","zh-cn","vi-vn","tr-tr","th-th","ru-ru","ro-ro","pt-br","pl-pl","ko-kr","ja-jp","it-it","id-id","hu-hu","fr-fr","es-mx","es-es","es-ar","en-us","en-sg","en-pl","en-ph","en-gb","en-au","el-gr","de-de","cs-cz"],"defaultLanguage":"en-us","generateDefaultLanguagePage":true,"siteUrl":"https://static-staging.rgpub.io/gatsbinger/","redirect":false,"i18nextOptions":{"initImmediate":false,"interpolation":{"escapeValue":false},"ns":["second","first","404"],"defaultNS":"first","load":"currentOnly","keySeparator":".","nsSeparator":":","returnObjects":true,"lowerCaseLng":true,"cleanCode":true,"react":{"useSuspense":true}},"pages":[{"matchPath":"/:lang?/example-one","getLanguageFromPath":true,"excludeLanguages":["ar-ae","zh-tw","zh-cn","vi-vn","tr-tr","th-th","ru-ru","ro-ro","pt-br","pl-pl","ko-kr","ja-jp","it-it","id-id","hu-hu","fr-fr","es-mx","es-es","es-ar","en-us","en-sg","en-pl","en-ph","en-gb","en-au","el-gr","de-de","cs-cz"]}]},
    },{
      plugin: require('../node_modules/@riotgames/gatsby-plugin-geotargeting/gatsby-browser.ts'),
      options: {"plugins":[],"supportedLocales":["ar-ae","zh-tw","zh-cn","vi-vn","tr-tr","th-th","ru-ru","ro-ro","pt-br","pl-pl","ko-kr","ja-jp","it-it","id-id","hu-hu","fr-fr","es-mx","es-es","es-ar","en-us","en-sg","en-pl","en-ph","en-gb","en-au","el-gr","de-de","cs-cz"],"defaultLanguage":"en-gb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fcff2561bf848809b22c76a2f87f9b55"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
